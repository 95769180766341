<template>
  <b-row v-if="items.length > 0" class="match-height">
    <b-col v-for="(provider, i) in items" :key="`provider-${i}`" cols="12" md="4" sm="8" class="position-relative">
      <b-card no-body class="pl-1 pt-1 provider-wrapper position-relative" style="overflow: hidden;">
        <b-row class="pb-1">
          <b-col md="4" sm="8">
            <b-avatar
              :src="provider.logo"
              :text="avatarText(provider.name)"
              :variant="`light-primary`"
              size="120px"
              rounded
            />
          </b-col>
          <b-col>
            <div>
              <span>Name</span>
              <h4 class="mb-50">{{ provider.name }}</h4>

              <span>Country</span>
              <h4 class="mb-50">{{ provider.country }}</h4>

              <span>Region/State/Province</span>
              <h4 class="mb-50">{{ provider.region }}</h4>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col cols="12">
      <div class="my-2">
        <b-pagination :value="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" @change="$emit('update:currentPage', $event)">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BLink,
  BBadge,
  BMedia,
  BButton,
  BAvatar,
  BOverlay,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormInput,
  BMediaBody,
  BInputGroup,
  BMediaAside,
  BPagination,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormInput,
    BMediaBody,
    BPagination,
    BMediaAside,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    currentPage: { type: Number, required: true },
    totalRecords: { type: Number, required: true },
    perPage: { type: Number, required: true },
    detailPage: { type: String, required: true },
    items: { type: Array, required: true },
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
.bg-red {
  background-color: red !important;
}
.provider-wrapper {
  position: relative;

  .text-lg {
    font-size: 13px;
  }
  .provider-status {
    display: block;
    text-transform: uppercase;
    width: 50%;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    transform: rotate(-45deg);
    right: -40px;
    bottom: 30px;
    margin-left: auto;
    overflow: hidden;
  }
  .provider-status.success {
    background-color: darkgreen;
  }
  .provider-status.warning {
    background-color: darkorange;
  }
  .provider-status.danger {
    background-color: darkred;
  }
}
</style>
